<template>
<div class="privacy-container">
        <!-- <navbar /> -->
        <div class="container">
            <div class="row">
                <div class="col-xs-12 col-md-12 col-lg-12">
                    <p>
                        At Desert Link Tourism, we respect our valued customer's privacy and ensure not to collect any information other than the information relevant to make booking with us. As such, we strive to take care of our customer’s right to privacy in connection with their interaction with this website. Our scope of commitment as part of the use of your information is outlined herein.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-md-12 col-lg-12">
                    <h4>1. Collection of Personal Information</h4>
                    <p>
                       We collect your information mainly when you contact us to inquire us about our products and services or make a booking with us. This usually includes your name , contact details, email id, physical address, credit card or payment details, travel requisites and referral source. Upon the submission of information, you give consent to Desert Link Tours to use your information to process orders in an accurate and prompt manner.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-md-12 col-lg-12">
                    <h4>2. Use of Information</h4>
                    <p>
                        Any personal information of a client obtained through this website will be utilized by Desert Link Tours to process his or her booking, verify credit card details, and provide relevant information associated with client’s travel or any other subsidiary services he or she would like to avail of. This information will also be used for auditing, research and activities focused to improve the performance of our website. 
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-md-12 col-lg-12">
                    <h4>3. Privacy of Your Information</h4>
                    <p>
                        All information provided by our customers during online booking, such as their name, address ,email id and credit card details is considered private, and will not be disclosed or sold to anyone except for certain suppliers or third parties whose involvement in the loop is fundamental for the successful processing of your order. But before disclosing your information, we make sure that these third parties abide by our privacy policy and adhere to strict safety measures.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-md-12 col-lg-12">
                    <h4>5. Non-Personal Information</h4>
                    <p>
                        Desert Link Tours consistently collects data from website, with the help of patterns via web logs and third party service providers. But this data is mostly deployed to evaluate the efficiency of our websites’ content and features.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-md-12 col-lg-12">
                    <h4>6. Opt-Out</h4>
                    <p>
                        We provide options for visitors to our website to ‘opt-out’ of having their personal information used for certain purposes. For instance, if you don’t want to receive any marketing material by way of newsletter or promotional emails, you can choose to request us not to send advertising information from Desert Link Tours or our affiliate websites.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-md-12 col-lg-12">
                    <h4>7. Contests and Surveys</h4>
                    <p>
                        Desert Link Tours conducts contests, drawings and surveys every now and then. Some contents are organized in collaboration with a third-party sponsor, and visitors to our websites will be informed at the time of the contest regarding the involvement of a particular third party and their extent of using your personal information. Since participating in these contests is voluntary, it’s solely at your discretion whether to partake in them and reveal your personal information.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-md-12 col-lg-12">
                    <h4>8. Secured Transaction</h4>
                    <p>
                        To maintain accuracy of data and avoid unauthorized access of our client’s personal information, we make sure that all transactions are carried out through our secured server. Moreover, we utilize technical safeguard system such as encryption, socket layers, and firewalls to secure your sensitive information like credit card details.
                    </p>
                </div>
            </div>
        </div>
        <footer-home />
    </div>
    
</template>
<script>
import FooterHome from '../components/FooterHome.vue'
// import Navbar from '../components/Navbar.vue'
export default {
    data() {
        return {

        }
    },
    components: {
        // Navbar,
        FooterHome

    }
}
</script>
<style lang="scss" scoped>

.privacy-container {
    height: 100%;
   //  background: url('../assets/backgroundColor.png');

    .container {
        padding: 20px 0;
    }
}

.row {
    margin: 10px;
    font-family: 'Open Sans', sans-serif;

    h4 {
        font-weight: 700;
    }

    h5 {
        font-weight: 500;
    }
    
    p {
        text-align: justify;
    }
}

</style>